import { calculateExpectedForWeek } from '../../../../utils/lookahead-common';
import { calculateCommitment } from '../../../../views/weeklyPlan/weeklyPlan.helper';
import { findDeepGetActivityOfTask } from '../../../../views/lookahead/planification/index.helper';
import { findDeepGetTask } from '../../GanttVisualization.helper';
import { ganttAPI } from '../../../../utils/customGanttPlugin';
import { TimerManagerSingleton } from '../../../../utils/timerManager';

const RenderWeeklyCommit = ({
  column,
  activities,
  gantt,
  t,
  onlyRead,
  task,
  volatileTaskData,
  haveWeeklyPlan = false,
  dateRange,
  updateCommitment
}) => {
  const timerManager = TimerManagerSingleton.getInstance();

  /** Task and parentask ref from lookahead */
  const isOnlyReadElement = onlyRead || task.readonly;
  if (!task.isTask) return null;
  if (task.is_milestone) return null;

  const findAct = findDeepGetActivityOfTask(window.activities, 'id', task.id);
  let activityReference;
  if (findAct) activityReference = findAct;
  if (!activityReference) return null;

  const taskFromLookahead = findDeepGetTask(activities, 'id', task.id);
  if (!taskFromLookahead) return null;

  let parentTask = null;

  if (taskFromLookahead) {
    if (taskFromLookahead.parent_id) {
      const doesExistAtReferenceParent = findDeepGetTask(
        activities,
        'id',
        taskFromLookahead.parent_id
      );
      parentTask = doesExistAtReferenceParent || null;
    }
  }

  if (
    (!task.isTask && !task.is_milestone) ||
    (taskFromLookahead && taskFromLookahead.children.length)
  ) {
    return '';
  }

  const onChangeWeeklyCommitment = async (e) => {
    /** validate commitment < progress */
    if (parseFloat(e) < parseFloat(taskFromLookahead.progress)) {
      return false;
    } else if (parseFloat(e) > 100) {
      return false;
    } else {
      taskFromLookahead[column.name] = e;
      task[column.name] = e;
      calculateCommitment(
        taskFromLookahead,
        parentTask || activityReference,
        updateCommitment,
        haveWeeklyPlan,
        activityReference
      );
    }
    gantt.updateCustomCommitmentPercentaje(task, true);

    const weeklyCommitCallback = () => {
      gantt.updateTask(task.id);
    };
    timerManager.registerAutoTimeout(
      weeklyCommitCallback,
      500,
      'renderWeeklyCommitTimer'
    );

    const weeklyCommitAutoScheduleCallback = () => {
      gantt.autoSchedule();
    };
    timerManager.registerAutoTimeout(
      weeklyCommitAutoScheduleCallback,
      1500,
      'renderWeeklyCommitAutoScheduleTimer'
    );
  };

  task.onChangeWeeklyCommitment = onChangeWeeklyCommitment;

  let expWeek = 0;

  if (dateRange.start !== undefined && dateRange.end !== undefined) {
    const startDate = new Date(dateRange.start);
    const endDate = new Date(dateRange.end);

    const newDateRange = {
      start: startDate,
      end: endDate
    };

    expWeek = calculateExpectedForWeek(
      taskFromLookahead,
      ganttAPI,
      findAct.calendarId,
      null,
      newDateRange
    );
  }

  const validateCommitment = (commitmentValue) => {
    if (
      commitmentValue !== undefined &&
      commitmentValue !== null &&
      commitmentValue !== '' &&
      commitmentValue !== 0 &&
      commitmentValue !== '0' &&
      !isNaN(commitmentValue)
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (onlyRead) {
    return `
            <span
                className="vertical-center"
                style={{ backgroundColor: 'transparent' }}>
                ${parseFloat(taskFromLookahead[column.name]).toFixed(
                  Number.isInteger(parseFloat(taskFromLookahead[column.name]))
                    ? 0
                    : 2
                )}%
            </span>`;
  }

  /** Kind of return, depending on if it already has an weekly commited relation */
  if (taskFromLookahead.showPureSpanCommitment) {
    volatileTaskData[taskFromLookahead.id] = {
      ...volatileTaskData[taskFromLookahead.id],
      commitment_percentaje: task.commitment_percentaje
    };
    return `<span className="vertical-center" style="cursor: 'not-allowed'; left: -2;">
                ${parseFloat(task.commitment_percentaje).toFixed(
                  Number.isInteger(parseFloat(task.commitment_percentaje))
                    ? 0
                    : 2
                )}%
            </span>`;
  } else {
    return `<span
                class="vertical-center"
                style="padding-left: ${isOnlyReadElement ? 0 : -2}px"
            >
                ${
                  validateCommitment(task.commitment_percentaje)
                    ? parseFloat(task.commitment_percentaje).toFixed(
                        Number.isInteger(parseFloat(task.commitment_percentaje))
                          ? 0
                          : 2
                      )
                    : expWeek !== 0
                      ? parseFloat(expWeek).toFixed(
                          Number.isInteger(parseFloat(expWeek)) ? 0 : 2
                        )
                      : task.commitment_percentaje
                }%
            </span>`;
  }
};
export default RenderWeeklyCommit;
