import React from 'react';
import {
  getRecursiveFromParentTask,
  getTask
} from '../../../../utils/lookahead-common';
import EditableInput from '../../../EditableInput';
import {
  findDeepGetTask,
  isGrouped,
  updateAsyncTaskGanttV2
} from '../../GanttVisualization.helper';
import { InputNumber } from 'antd';
import NumberFormat from 'react-number-format';
import MoneySymbolString from '../../../MoneySymbolString';
import cloneDeep from 'lodash/cloneDeep';
import { TimerManagerSingleton } from '../../../../utils/timerManager';

export const testIds = {
  RENDER_NUMERIC_CONTAINER: 'RENDER_NUMERIC_CONTAINER'
};

const WithTestContainer = () => {
  return `<span data-testid=${testIds.RENDER_NUMERIC_CONTAINER}>
        </span>`;
};

const RenderNumeric = ({
  task = {},
  node = {},
  column = {},
  activities = [],
  gantt = {},
  t = (n) => '',
  onlyRead = true,
  setEditedInput = () => {},
  index = 0,
  onChange = () => {},
  lastLevelActivities = []
}) => {
  const timerManager = TimerManagerSingleton.getInstance();

  if (!task) return '';
  const taskId = task?.id ? task.id : 0;
  const dataTestId =
    'data-testid="' +
    testIds.RENDER_NUMERIC_CONTAINER +
    '_' +
    taskId +
    '_' +
    column?.name +
    '"';

  const isOnlyReadElement =
    onlyRead || task.readonly || gantt?.config?.readonly;
  const valueData = column?.name ? task[column.name] : 0;
  const navigatorLang = navigator.language || navigator.userLanguage;
  if (!navigatorLang) return '';
  if (task?.is_milestone) return WithTestContainer();

  /** symbol currency by default  */
  const currency_symbol = ['cost', 'expected_cost'].includes(column?.name)
    ? MoneySymbolString()
    : '';

  if (column?.name !== 'cost' && !task?.isTask) return WithTestContainer();

  let activityReference;

  /** get reference task (taskFromLookahead) from activities */
  const getReferenceFromLookahead = (ganttRefTask) => {
    const task = ganttRefTask;
    const activityReferenceLvl = activities.find(
      (ac) => ac.id == task?.activityReference?.proplannerId
    );
    activityReference = activityReferenceLvl;
    if (!activityReference) return null;
    const newLookaheadTaskRef = getTask(task.id, null, activityReferenceLvl);
    if (newLookaheadTaskRef?.length) {
      return newLookaheadTaskRef[0];
    } else {
      return null;
    }
  };

  const taskFromLookahead = getReferenceFromLookahead(task);
  let classActivity = '';
  if (task.type === 'main') {
    classActivity = 'is-activity';
  } else {
    if (!taskFromLookahead) return WithTestContainer();
  }

  /** disabled value for parent tasks  */
  let disabled = true;
  if (taskFromLookahead) {
    disabled = taskFromLookahead?.children?.length > 0;
  }
  /** disable if is grouped */
  if (isGrouped()) {
    disabled = true;
  }

  /** disabled value for parent tasks, get parent task  */
  let parentTask = null;
  if (taskFromLookahead) {
    if (taskFromLookahead.parent_id) {
      const doesExistAtReferenceParent = findDeepGetTask(
        activities,
        'id',
        taskFromLookahead.parent_id
      );
      parentTask = doesExistAtReferenceParent || null;
    }
  }

  /** this function runs after updating the value */
  const updateAsyncTask = async (taskLocal) => {
    /** update task's value numeric */
    gantt.isRenderingOnProcess = true;
    await updateAsyncTaskGanttV2(
      taskFromLookahead,
      column?.name,
      taskLocal[column?.name],
      gantt,
      true,
      false,
      false,
      true
    );

    /** update parent */
    if (taskFromLookahead.parent_id) {
      updateAsyncParent(taskFromLookahead.parent_id);
    }
  };

  /** this function update column?.name recursively */
  const updateAsyncParent = (idTask) => {
    const ganttObjectRef = gantt.getTask(idTask);
    if (!ganttObjectRef.isTask) {
    } else {
      const getReferenceTask = getReferenceFromLookahead(ganttObjectRef);
      const taskRef = getReferenceTask || null;
      getRecursiveFromParentTask(taskRef, column?.name, () => {}, updateData);
      if (taskRef.parent_id) {
        updateAsyncParent(taskRef.parent_id);
      }
      gantt.isRenderingOnProcess = true;
      gantt.optimizedRender();
    }
  };

  /** this function intercepts the save call, and adds data */
  const updateData = async (taskCallback) => {
    gantt.isRenderingOnProcess = true;
    await updateAsyncTaskGanttV2(
      taskCallback,
      column?.name,
      taskCallback[column?.name],
      gantt,
      true,
      false,
      false,
      true
    );
  };

  /** calculate at render */
  if (task.type !== 'main' && taskFromLookahead?.children?.length) {
    getRecursiveFromParentTask(
      taskFromLookahead,
      column?.name,
      () => {},
      updateData
    );
    if (task[column?.name] !== taskFromLookahead[column?.name]) {
      task[column?.name] = taskFromLookahead[column?.name];
    }
  }

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig[column?.name];
  }

  function formatValue(value) {
    if (!value.toFixed) return '';

    if (['real_endowment', 'plan_endowment'].includes(column?.name)) {
      value = Math.round(parseFloat(value));
    }

    return Number(
      value.toFixed(Number.isInteger(parseFloat(value)) ? 0 : 2)
    ).toLocaleString(t('lang') === 'en' ? 'en-US' : 'es-ES', {
      useGrouping: true
    });
  }

  let valueNumber = task[column?.name]
    ? formatValue(parseFloat(task[column?.name]))
    : 0;

  task['onChangeNumeric_' + column?.name] = (newValue) => {
    timerManager.clearTimer('renderNumericTimer_' + column?.name);
    const numericCallback = async () => {
      if (['real_endowment', 'plan_endowment'].includes(column?.name)) {
        newValue = Math.round(newValue);
      }

      task[column?.name] = newValue;

      await updateAsyncTask(task);
      onChange && onChange(task, parentTask, activityReference);
    };
    timerManager.registerAutoTimeout(
      numericCallback,
      1000,
      'renderNumericTimer_' + column?.name
    );
  };

  const disableTask =
    (column?.name === 'cost' && task.type === 'main') ||
    disabled ||
    column?.calculated;
  task['disabledNumeric_' + column?.name] = disableTask === true ? true : false;

  return ` <div ${dataTestId}>
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                
                <span class="${`vertical-center ${classActivity}`}">
                    ${currency_symbol + '' + valueNumber}
                </span>
                ${
                  ['total_quantity'].includes(column?.name)
                    ? `<span style="${isOnlyReadElement ? 'margin-left: 5px ' : null}" >${task.tagResource ? task.tagResource : ''}</span>`
                    : ''
                }
            </div>
        </div>`;
};

export default RenderNumeric;
