import * as projectActions from '../../../redux/slices/projectSlice';
import { sectorService, taskService } from '../../../services';
import { compareValues } from '../../../utils';
import { ganttAPI } from '../../../utils/customGanttPlugin';
import {
  calculateExpected,
  calculateExpectedCost,
  notifyMessage
} from '../../../utils/lookahead-common';
import { transformHourToDays } from '../../../views/ganttContainer/gantt/gantt.helper';
import {
  avoidOptimizationTimelineBlinking,
  findDeepGetTask,
  getReferenceFromLookahead,
  updateAsyncActivityGantt,
  updateAsyncTaskGanttV2,
  updateProgressAndPonderator
} from '../GanttVisualization.helper';
import cloneDeep from 'lodash/cloneDeep';
import { buildMultiselectAPI } from '../../../assets/gantt/ext/proplannerMultiselect.api';
import {
  handleCopy,
  handlePaste
} from '../../../assets/gantt/ext/copyPasteAPI';
import moment from 'moment';
import {
  findDeepGetActivityOfTask,
  hasUnreleasedConstraints,
  updateTaskRestrictedStatus
} from '../../../views/lookahead/planification/index.helper';
import { regexDateFormat } from '../../GanttSettings/constants';
import { Colors } from '../../../constants/colors.constants';
import {
  save_single_task_lookahead,
  update_custom_commmitment_task_lookahead,
  workerService
} from '../../../services/serviceworker.service';
import { TimerManagerSingleton } from '../../../utils/timerManager';
import { LEAN_STATUS } from '../../../constants/leanStatus.constants';

/**
 * This function creates a set of util functions to handle recursive behaviour,
 * or calculating some global status stuff.
 * @param {*} gantt Gantt dhtmlx instance object
 * @param {*} setMassiveSelectionGannt Setter function to notify from Gantt API to React API checkbox selection to pure lookahead view behaviour
 * @param {*} setWeeklyModalData
 * @param {*} dispatch Dispatch function from redux
 * @param {*} projectState State project from redux
 * @param {*} t Translate function
 * @param {*} setMassiveSelection Massive selection setter from Proplanner API
 */
export const buildUtils = (
  gantt,
  setMassiveSelectionGannt,
  setWeeklyModalData,
  dispatch,
  projectState,
  t,
  setMassiveSelection
) => {
  const timerManager = TimerManagerSingleton.getInstance();
  /**
   * This function handles scale timeline bug
   * @returns null when reference are not loaded
   */
  const fixScaleDateHeader = () => {
    const executeFix = () => {
      if (!gantt.$ui) return;
      if (!gantt.$ui.getView) return;
      gantt.$ui.getView('timeline')._refreshScales();
    };

    timerManager.registerAutoTimeout(
      executeFix,
      250,
      'renderScaleDateHeaderTimer1'
    );

    timerManager.registerAutoTimeout(
      executeFix,
      500,
      'renderScaleDateHeaderTimer2'
    );

    timerManager.registerAutoTimeout(
      executeFix,
      750,
      'renderScaleDateHeaderTimer3'
    );

    timerManager.registerAutoTimeout(
      executeFix,
      1000,
      'renderScaleDateHeaderTimer4'
    );

    timerManager.registerAutoTimeout(
      executeFix,
      1250,
      'renderScaleDateHeaderTimer5'
    );

    timerManager.registerAutoTimeout(
      executeFix,
      1500,
      'renderScaleDateHeaderTimer6'
    );

    timerManager.registerAutoTimeout(
      executeFix,
      1750,
      'renderScaleDateHeaderTimer7'
    );

    timerManager.registerAutoTimeout(
      executeFix,
      2000,
      'renderScaleDateHeaderTimer8'
    );
  };

  gantt.fixScaleDateHeader = fixScaleDateHeader;

  gantt.selectedTasksMultiDrag = [];
  gantt.setMassiveSelectionGannt = setMassiveSelectionGannt;
  gantt.updateCustomCommitmentPercentaje = async (task, value = false) => {
    task.hasCustomCommitmentPercentaje = value;

    workerService.callAction(
      update_custom_commmitment_task_lookahead,
      { taskId: task.id, value },
      () => {
        taskService.updateCustomCommitmentPercentaje(task.id, value);
      }
    );
  };

  function initDateFunctions() {
    gantt.dateToStr = gantt.date.date_to_str(
      gantt.hashMoment2Date[gantt.currentDateFormat]
    );
    gantt.strToDate = gantt.date.str_to_date(
      gantt.hashMoment2Date[gantt.currentDateFormat]
    );
  }

  gantt.initDateFunctions = initDateFunctions;

  /** This object, will map task id, going through a process */
  const stack = {};
  gantt.proplannerProcessStack = stack;

  /**
   * This function turn on a process in the stack, and after a time it turn off
   * @param {*} id ID of task which is going to be attached a proplanner process
   * @param {*} processToAttach  name of function which is starting a process
   * @param {*} timer Time to keep enable in the stack
   * @returns true if the stack dont have an attached process in the same task, and false if there is the same process already running in background
   */
  const attachToStack = (id, processToAttach, timer = 1000) => {
    const stack = gantt?.proplannerProcessStack;
    if (!stack || !processToAttach) return;
    if (stack && stack[id] && stack[id][processToAttach]) {
      return;
    }
    if (!stack[id]) {
      stack[id] = {};
    }
    stack[id][processToAttach] = true;

    const stackCallback = () => {
      stack[id][processToAttach] = false;
    };
    timerManager.registerAutoTimeout(stackCallback, timer, 'renderStackTimer');
    return true;
  };
  gantt.attachToStack = attachToStack;

  gantt.changeCurrentDateFormat = async (dateFormat) => {
    const doesExistAtOptions = gantt.dateFormatOptions.find(
      (el) => el == dateFormat
    );
    if (doesExistAtOptions) {
      const copySector = { ...gantt.currentSector };
      copySector.dateFormat = dateFormat;
      delete copySector.calendars;
      const res = await sectorService.update(copySector);

      gantt.currentDateFormat = dateFormat;
      if (res) {
        const resp = await sectorService.index();
        if (!resp) return;
        const filterSectors = resp.sectors.filter(
          (e) =>
            e.projectId === projectState.projectSelected && e.status === true
        );
        filterSectors.sort(compareValues('order'));
        dispatch(projectActions.setAllSectors(filterSectors));
      }
      gantt.runAndUnmountTimeout(() => {
        gantt.optimizedRender();
      }, 200);
    }
  };

  const executeAsyncAutoscheduleToAvoidFreeze = () => {
    /** Both flags helps dhtmlx component to freeze memory through their real time autoschedule */
    gantt.maxPerformance = false;
    gantt.shouldAutoscheduleSync = true;
    gantt.copyBehaviourFromInline = false;

    gantt.avoidAutoschedule = false;
  };
  gantt.executeAsyncAutoscheduleToAvoidFreeze =
    executeAsyncAutoscheduleToAvoidFreeze;

  gantt.reviewWeeklyPlan = () => {
    gantt.reactHistory && gantt.reactHistory.push('/weeklyplan/commintments');
  };

  gantt.commitWeeklyPlan = (_) => {
    const tasksToCommit = gantt.getTaskBy(
      (t) => t.isInsideWeekly && t.lean_status === 'Will'
    );
    setWeeklyModalData({
      visible: true,
      tasksToCommit
    });
  };

  gantt.checkIfTaskIsInsideOfWeekly = (
    task,
    isMassive = false,
    accumulatedTasks = []
  ) => {
    /** In this example, we check if there is a process in background, if attachToStack function return true, means that the stack was without this process so was added, if false, means this process is running already */
    if (attachToStack(task.id, 'checkIfTaskIsInsideOfWeekly')) {
      if (gantt.weeklyRangeObject && (task.isTask || task.is_milestone)) {
        task.checkedIfInsideWeekly = false;
        const { start_date, end_date } = gantt.weeklyRangeObject;
        const start = start_date.getTime();
        const end = end_date.getTime();
        const taskStart = task.start_date.getTime();
        const taskEnd = task.end_date.getTime();

        const isTaskInsideCommitment =
          gantt?.isWeeklyCommited?.taskcommitments?.find(
            (committedTask) => committedTask.taskId === task.id
          );
        const isTaskInsideOfRange =
          (taskStart >= start && taskEnd <= end) ||
          (taskStart >= start && taskStart <= end) ||
          (start >= taskStart && start <= taskEnd);
        const isCommitmentOpenWithCommitedTasks =
          !gantt.isWeeklyCommited.closed &&
          gantt.isWeeklyCommited.taskcommitments;
        const isTaskLeanWill = task.lean_status === LEAN_STATUS.WEEKLY_PLAN;
        const isTaskInsideWithOtherLean = task.inside_weekly_other_lean;

        updateTaskRestrictedStatus(task);

        if (!hasUnreleasedConstraints(task)) {
          if (gantt.isWeeklyCommited) {
            if (isCommitmentOpenWithCommitedTasks && isTaskInsideCommitment) {
              // Dont matter the lean_status, if it is inside of weekly and commited is orange !
              task.lean_status = LEAN_STATUS.COMMITTED;
              task.isInsideWeekly = true;
              task.isCommitted = true;
            } else if (isTaskLeanWill && !isTaskInsideOfRange) {
              if (isTaskLeanWill) task.lean_status = LEAN_STATUS.SHOULD; // task.lastRegisteredLeanStatus || 'Debit' PP-1544
              task.isInsideWeekly = false;
            } else if (isTaskInsideOfRange) {
              task.lean_status = LEAN_STATUS.WEEKLY_PLAN;
              task.isInsideWeekly = true;
            }
          } else {
            if (isTaskInsideOfRange) {
              if (!isTaskInsideWithOtherLean) {
                task.lean_status = LEAN_STATUS.WEEKLY_PLAN;
              } else {
                task.isInsideWeekly = false;
              }
              task.isInsideWeekly = true;
            } else {
              if (
                !isTaskInsideWithOtherLean &&
                task.lean_status !== LEAN_STATUS.CAN
              ) {
                task.lean_status = LEAN_STATUS.SHOULD; // tasklean_status.lastRegisteredLeanStatus || 'Debit'
              }
              task.isInsideWeekly = false;
            }
          }
        } else if (isTaskInsideOfRange) {
          task.isInsideWeekly = true;
        }

        const taskFromLookaheadRef = getReferenceFromLookahead(
          task,
          gantt.activities
        );
        /** This one represents last update for gantt instance reference */
        if (!taskFromLookaheadRef) return;
        const formattedTaskEnd = moment(taskEnd).format('YYYY/MM/DD HH:mm');
        taskFromLookaheadRef.end_date = formattedTaskEnd;
        const formattedTaskStart = moment(taskStart).format('YYYY/MM/DD HH:mm');
        taskFromLookaheadRef.start_date = formattedTaskStart;
        taskFromLookaheadRef.lean_status = task.lean_status;
        taskFromLookaheadRef.customCommitmentPercentaje =
          task.hasCustomCommitmentPercentaje;
        taskFromLookaheadRef.inside_weekly_other_lean =
          task.inside_weekly_other_lean;

        if (isMassive) {
          accumulatedTasks.push(taskFromLookaheadRef);
        } else {
          workerService.callAction(
            save_single_task_lookahead,
            taskFromLookaheadRef,
            () => {
              taskService.update(taskFromLookaheadRef);
            }
          );
        }

        task.checkedIfInsideWeekly = true;
      }
    }
  };

  /** Multiselect API integration */
  buildMultiselectAPI(gantt, setMassiveSelectionGannt, setMassiveSelection);

  gantt.callbackAfterAddConstraint = (updatedTasks) => {
    updatedTasks.forEach((t) => {
      const ganttRef = gantt.getTask(t.id);
      if (ganttRef) {
        ganttRef.lean_status = t.lean_status;
        /** Fix change color when adding roadblocks */
        const setter = getColorSetter(gantt.visualizationColorActive);
        if (setter) {
          setter(ganttRef);
        }
      }
    });
    gantt && gantt.refreshData();
  };

  const colorStatus = {
    Advancement: '#309FE9',
    Overdue: '#E50101',
    Waiting: '#121212',
    Doing: '#F59D04',
    Done: '#34AF00'
  };

  const defineColorByStatus = (task, render = false) => {
    if (task.type == 'milestone') return;

    const COLOR_DEFAULT = Colors.GRAY_1;
    task.progressSolidColor = colorStatus[task.status]
      ? colorStatus[task.status]
      : COLOR_DEFAULT;
    task.color = `${task.progressSolidColor}4D`;
    if (render) gantt.refreshData();
  };

  gantt.validateFormatDay = (inputString) => {
    const dateFormat = gantt.currentDateFormat;
    const regex = regexDateFormat[dateFormat];
    if (regex && !regex.test(inputString)) {
      notifyMessage({
        title: t('format_error_title'),
        message: t('format_error_content'),
        type: 'warning'
      });
      return false;
    }
    return true;
  };

  gantt.defineColorByStatus = defineColorByStatus;

  const leanStatusColor = {
    Restricted: '#E50101',
    Debit: '#586666',
    Can: '#34AF00',
    Will: '#2C3421',
    Committed: '#F59D04'
  };

  const defineColorByLeanStatus = (task, render = false) => {
    if (task.type == 'milestone') return;

    const COLOR_DEFAULT = Colors.GRAY_1;
    task.progressSolidColor = leanStatusColor[task.lean_status]
      ? leanStatusColor[task.lean_status]
      : COLOR_DEFAULT;
    task.color = `${task.progressSolidColor}4D`;
    if (render) gantt.refreshData();
  };

  gantt.defineColorByLeanStatus = defineColorByLeanStatus;

  const defineColorByTags = (task, render = false) => {
    if (task.type == 'milestone') return;

    const COLOR_DEFAULT = Colors.GRAY_1;
    const tags = task.tags;

    task.progressSolidColor =
      tags && tags.length && tags[0] ? tags[0].description : COLOR_DEFAULT;
    task.color = `${task.progressSolidColor}4D`;
    if (render) gantt.refreshData();
  };
  gantt.defineColorByTags = defineColorByTags;

  /**
   * This function set the color by the sub selected for a task
   * @param {*} task Task object to be checked sub color
   * @param {*} render Boolean that defines if gantt must render
   * @returns Null if task object is milestone
   */
  const defineColorBySubcontract = (task, render = false) => {
    if (task.type == 'milestone') return;
    const COLOR_DEFAULT = Colors.GRAY_1;
    let color;
    const subId = task.subcontractId;

    if (subId) {
      const subcontractObject = gantt.subContracts.find((el) => el.id == subId);
      if (subcontractObject) {
        color = subcontractObject.color;
      }
    }

    if (!color) {
      color = COLOR_DEFAULT;
    }
    task.progressSolidColor = color;
    task.color = `${color}4D`;
    if (render) gantt.refreshData();
  };

  gantt.defineColorBySubcontract = defineColorBySubcontract;
  const getColorSetter = (colorSchemeType) =>
    ({
      status: defineColorByStatus,
      leanstatus: defineColorByLeanStatus,
      subcontract: defineColorBySubcontract,
      tags: defineColorByTags
    })[colorSchemeType];

  const changeScaleVisualization = {
    hours: (_) => gantt.ext.zoom.setLevel(3),
    days: (_) => gantt.ext.zoom.setLevel(4),
    weeks: (_) => gantt.ext.zoom.setLevel(3),
    month: (_) => gantt.ext.zoom.setLevel(2),
    quarters: (_) => gantt.ext.zoom.setLevel(1),
    years: (_) => gantt.ext.zoom.setLevel(0)
  };

  gantt.changeScaleVisualization = (scale = 'weeks') =>
    changeScaleVisualization[scale]
      ? changeScaleVisualization[scale]()
      : gantt.ext.zoom.setLevel(3);

  gantt.scrollToTodayAtChart = () => {
    gantt.avoidShowDateOptimization = true;
    gantt.showDate(new Date());
  };

  gantt.changeVisualizationOption = (
    colorSchemeType = gantt.visualizationColorActive
  ) => {
    gantt.batchUpdate(() => {
      const colorSetter = getColorSetter(colorSchemeType);
      if (colorSetter) {
        gantt.getTaskByTime().forEach((t) => colorSetter(t));
      } else {
        return;
      }

      gantt.refreshData();
      gantt.visualizationColorActive = colorSchemeType;
    });

    // dispatch(ganttActions.notifyGanttVisualizationConfigChange({ colorSchemeType }))
  };

  /**
   * This function run a callback on a settimeout function, and then unmount it to avoid freeze mem
   * @param {*} callback Function definition to be called inside settimeout
   * @param {*} timer Time to be used at settimeout
   */
  gantt.runAndUnmountTimeout = (callback, timer) => {
    const runAndUnmountCallback = () => {
      callback();
    };
    timerManager.registerAutoTimeout(
      runAndUnmountCallback,
      timer,
      'runAndUnmountTimeout'
    );
  };

  gantt.handleTaskBeforeAfter = (task, isBigBar) => {
    const htmlNode = task.barHtmlNode;
    htmlNode.classList.remove(htmlNode.classList.length - 1);
    htmlNode.classList.add(
      `${task.barWidth > 5 ? 'show' : 'hide'}-parent-task-markers`
    );
    htmlNode.style.setProperty('--borderMarkersColor', task.progressSolidColor);
  };

  /**
   * This function calculates ponderator for a given element on the graph.
   * @param {*} dhtmlxRef
   */
  gantt.reCalculateProgressAndPonderator = (dhtmlxRef) => {
    try {
      const reactRef = findDeepGetTask(window.activities, 'id', dhtmlxRef.id);
      const parentReactRef = findDeepGetTask(
        window.activities,
        'id',
        dhtmlxRef.parent
      ); // If this value is null, means that the ID belongs to an Activity, so then, it
      const activityReactRef = findDeepGetActivityOfTask(
        window.activities,
        'id',
        dhtmlxRef.id
      );
      if (reactRef && activityReactRef) {
        updateProgressAndPonderator({
          task: reactRef,
          parentTask: parentReactRef,
          activity: activityReactRef,
          projectState,
          updateDataPonderator: async (taskCallback) => {
            await updateAsyncTaskGanttV2(
              taskCallback,
              'ponderator',
              taskCallback.ponderator,
              gantt
            );

            const ponderatorCallback = () => {
              if (gantt.getTaskByTime()) {
                const dhtmlxRef = gantt.getTask(taskCallback.id);
                dhtmlxRef.ponderator = taskCallback.ponderator;
              }
            };
            timerManager.registerAutoTimeout(
              ponderatorCallback,
              500,
              'renderPonderatorTimer'
            );
          },
          updateDataProgress: async (taskCallback) => {
            await updateAsyncTaskGanttV2(
              taskCallback,
              'progress',
              taskCallback.progress,
              gantt
            );

            const progressCallback = () => {
              if (gantt.getTaskByTime()) {
                const dhtmlxRef = gantt.getTask(taskCallback.id);
                dhtmlxRef.progress = taskCallback.progress;
              }
            };
            timerManager.registerAutoTimeout(
              progressCallback,
              500,
              'renderProgressTimer'
            );
          },
          updateAsyncActivity: updateAsyncActivityGantt
        });
      }
    } catch (e) {
      console.log('Error on reCalculateProgressAndPonderator');
      console.log(e);
    }
  };

  /**
   * This function go deeple through childrens to calcualte a real value of expected progress
   * @param {*} activity Item to calculate expected
   */
  gantt.recursiveFunction = (activity) => {
    gantt.batchUpdate(() => {
      const childs = gantt.getChildren(activity.id);
      let projectExpectedFollowUp = 0;
      childs.map((child) => {
        /** Get object from Gantt Library */
        const childActivity = gantt.getTask(child);
        const childs = gantt.getChildren(child);
        const copy = { ...childActivity };

        if (childs.length) {
          gantt.recursiveFunction(childActivity);
        } else {
          copy.duration = transformHourToDays(copy.duration);
          childActivity.expected = gantt
            ? calculateExpected(copy, ganttAPI, copy.calendar_id)
            : 0;
          projectExpectedFollowUp =
            projectExpectedFollowUp +
            childActivity.expected * childActivity.ponderator;
          // Added this element to re calculate progress and ponderator/weigth
          gantt.reCalculateProgressAndPonderator &&
            gantt.reCalculateProgressAndPonderator(childActivity);
        }
      });

      activity.expected = projectExpectedFollowUp / 100;
    }, true);
  };

  gantt.calculateParentExpected = (parentActivities) => {
    gantt.batchUpdate(() => {
      parentActivities.map((activity) => {
        const finalChildren = gantt.getChildren(activity.id);
        let finalExpectedProgress = 0;
        finalChildren.map((child) => {
          const childObject = gantt.getTask(child);
          finalExpectedProgress =
            finalExpectedProgress +
            childObject.expected * childObject.ponderator;
        });
        activity.expected = finalExpectedProgress / 100;
      });
    }, true);
  };

  gantt.expectedParentCalculate = () => {
    gantt.batchUpdate(() => {
      const parentActivities = gantt.getTaskBy((task) => {
        if (gantt.getChildren(task.id).length) {
          return true;
        }
      });
      gantt.calculateParentExpected(parentActivities.reverse());
    });
  };

  gantt.updateGlobalStatus = () => {
    gantt.batchUpdate(() => {
      gantt.getTaskByTime().map((task) => {
        if (task.progress == 0) {
          task.status = 'Waiting';
        } else if (task.progress > 0 && task.progress < 100) {
          task.status = 'Doing';
        } else {
          task.status = 'Done';
        }
      });
    });
  };

  gantt.updateExpectedGlobal = async (specificActivityId) => {
    const isUpdateRunning = attachToStack('allTasks', 'updateExpectedGlobal');
    if (!isUpdateRunning) return;
    gantt.batchUpdate(() => {
      if (specificActivityId) {
        const activity = gantt.getTask(specificActivityId);
        if (activity) {
          gantt.recursiveFunction(activity);
        }
      } else {
        const activities = gantt.getTaskBy((t) => t.type === 'main');
        if (activities) {
          for (let index = 0; index < activities.length; index++) {
            const activity = activities[index];
            if (activity) gantt.recursiveFunction(activity);
          }
        }
      }
      gantt.expectedParentCalculate();
      gantt.updateGlobalStatus();
    }, true);
  };

  gantt.refreshVisualizations = (taskObject) => {
    try {
      const taskRef = gantt.getTask(taskObject.id);

      if (taskRef) {
        gantt.checkIfTaskIsInsideOfWeekly &&
          gantt.checkIfTaskIsInsideOfWeekly(taskRef);
        gantt.checkTaskVisualization && gantt.checkTaskVisualization(taskRef);
        gantt.refreshData();
      }
    } catch (error) {
      if (gantt.Sentry) {
        gantt.Sentry.captureMessage(error, 'debug');
      }
    }
  };

  gantt.fixActs = [];

  gantt.checkTaskVisualization = (task) => {
    getColorSetter(gantt.visualizationColorActive)(task);
  };

  /**
   * This function receives a date, and check if it goes longer than 4 years forward and backward
   * @param {*} date Date to be checked if is inside this time period
   * @returns True if this date is valid for project period, false if this date is out of the range
   */
  const checkLongTaskDate = (date) => {
    const momentDate = moment(date);
    const masterTask = gantt.getTaskByIndex(0);
    if (!masterTask) return;
    const endProject = masterTask?.end_date;
    const startProject = masterTask?.start_date;
    if (!endProject || !startProject) return;
    const forwardEnd = moment(endProject).add(20, 'years');
    const backwardStart = moment(startProject).subtract(20, 'years');
    if (!forwardEnd || !backwardStart) return;
    if (momentDate.isBefore(backwardStart) || momentDate.isAfter(forwardEnd))
      return;
    return true;
  };
  gantt.checkLongTaskDate = checkLongTaskDate;

  const updateTaskTiming = (task, newStartDate = false, dir = 'future') => {
    const calendar = gantt.getCalendar(task.calendar_id);
    if (calendar) {
      task.start_date = calendar.getClosestWorkTime({
        dir,
        date: newStartDate
          ? gantt.date.parseDate(newStartDate, 'xml_date')
          : task.start_date,
        unit: gantt.config.duration_unit,
        task: task
      });
      task.end_date = calendar.calculateEndDate(task);
      task.expected = calculateExpected(task, ganttAPI, task.calendar_id);
      task.expected_cost = calculateExpectedCost(
        task,
        ganttAPI,
        task.calendar_id
      );
    }
  };

  gantt.updateTaskTiming = updateTaskTiming;

  const setFinalHourDay = (task, avoidRender = false) => {
    const calendar = gantt.getCalendar(task.calendar_id);
    const clonedStartDate = cloneDeep(task.start_date);
    let clonedEndDate = cloneDeep(task.end_date);
    clonedEndDate.setHours(24);
    clonedEndDate.setMinutes(0);

    if (calendar) {
      clonedEndDate = calendar.getClosestWorkTime({
        dir: 'past',
        date: clonedEndDate,
        unit: gantt.config.duration_unit
      });
      const duration = calendar.calculateDuration(
        clonedStartDate,
        clonedEndDate
      );
      task.duration = duration;
      task.end_date = clonedEndDate;
      gantt.updateTaskTiming(task);
      if (!avoidRender) {
        gantt.optimizedRender();
      }
    }
  };

  gantt.setFinalHourDay = setFinalHourDay;

  const setInitialHourDay = (task) => {
    const clonedStartDate = cloneDeep(task.start_date);
    clonedStartDate.setHours(0);
    clonedStartDate.setMinutes(0);
    task.start_date = clonedStartDate;
    gantt.updateTaskTiming(task);
  };

  gantt.setInitialHourDay = setInitialHourDay;

  /**
   * This function adjust grid width when user resize its lower than total of active columns, or when resize its higher than the total
   * NOTE: Avoid touching this function without checking it with Tech Lead.
   */
  gantt.adjustGridWidth = () => {
    if (!gantt) return;
    const currentDOMElement = document.getElementsByClassName(
      'gantt_layout_cell gantt_layout gantt_layout_y  gantt_layout_cell_border_right'
    );
    if (!currentDOMElement && !currentDOMElement[0]) return;
    const originalGridWidth = parseInt(currentDOMElement[0].style.width);
    const totalGridWidth = gantt.config.grid_width;
    const middleScreenWidth = window.innerWidth * 0.5;
    gantt.refreshData();
    if (originalGridWidth < totalGridWidth) {
      gantt.hasCustomResize = true;
    } else if (totalGridWidth > middleScreenWidth) {
      gantt.hasCustomResize = false;
      gantt.$layout.$cells[0].$config.width = totalGridWidth;
      gantt.optimizedRender();
    } else if (
      gantt.config &&
      gantt.config.layout &&
      gantt.config.layout.cols instanceof Array &&
      gantt.config.layout.cols[0]
    ) {
      gantt.hasCustomResize = false;
      gantt.$layout.$cells[0].$config.width = totalGridWidth;
      gantt.optimizedRender();
    }
  };

  gantt.optimizedRender = () => {
    const uniquefixActs = [...new Set(gantt.fixActs)];
    if (uniquefixActs.length) {
      uniquefixActs.map((elem) => {
        const actGet = gantt.getTask(elem.idActivity);
        actGet.progress = parseFloat(elem.progress_fix);
      });
    }

    if (!gantt.isRenderingOnProcess) {
      gantt.render();
    }
  };

  gantt.reloadDuration = () => {
    gantt.batchUpdate(() => {
      gantt.eachTask((task) => {
        task.for_disable_milestone_duration = task.duration;
      });
    });
    gantt.optimizedRender();
  };

  /** DEPRECATED Fix keyboard navigation scroll */
  /* Remove the existing left/right shorctuts for header cells navigation */
  gantt.adjustGridScrollbar = () => {
    const ganttInsideGrid = document.getElementsByClassName('gantt_grid')[0];
    const ganttGridScrollbar = document.getElementsByClassName(
      'gantt_layout_cell gantt_hor_scroll'
    )[0];

    if (ganttGridScrollbar) {
      ganttGridScrollbar.scrollTo(ganttInsideGrid.scrollLeft, null);
    }

    gantt.optimizedRender();
  };

  gantt.handlerCtrlC = () => {
    const selectedTasks = gantt
      ?.serialize()
      ?.data?.filter((t) => t.visibleChecked || t.checked);
    const canCopyTasks = handleCopy(gantt, dispatch, t, selectedTasks);

    if (canCopyTasks) canCopyTasks.lookahead();
  };

  gantt.handlerCtrlV = () => {
    // const selectedTasks = gantt?.serialize()?.data?.filter(t => t.visibleChecked || t.checked);
    const copySector = { ...gantt.currentSector };
    const canPasteTasks = handlePaste(
      gantt,
      t,
      copySector.id,
      gantt.updateExpectedGlobal,
      false
    );

    if (canPasteTasks) canPasteTasks.lookahead();
  };

  /**
   * This function check if there is an activity or task and handles their recursive behaviour, also checks the visiblechecked state to keep it coherent
   * @param {*} id ID from el to be selected (activity or task)
   * @param {*} activityReferenceId If there is an task indeed, so then his activity id will be necessary to handle normal behaviour
   */
  const handleMultiSelectionWithActivities = (id, activityReferenceId) => {
    gantt.batchUpdate(() => {
      const ganttRef = gantt.getTask(id);
      ganttRef.checked = !ganttRef.checked;
      if (
        ganttRef.visibleChecked === true &&
        ganttRef.checked === true &&
        ganttRef.mustApplyVisibleChecked
      ) {
        ganttRef.checked = false;
      }
      if (ganttRef.isTask) {
        gantt.updateCheckboxRecursive(
          id,
          activityReferenceId,
          false,
          ganttRef.checked
        );
      } else {
        const children = gantt.getChildren(id);
        children.forEach((childTask) => {
          gantt.updateCheckboxRecursive(
            childTask,
            ganttRef.proplannerId,
            false,
            ganttRef.checked
          );
        });
      }

      const visibleCheckedCallback = () => {
        if (ganttRef.checked === false && !ganttRef.isTask) {
          ganttRef.visibleChecked = false;
        }
      };
      timerManager.registerAutoTimeout(
        visibleCheckedCallback,
        100,
        'renderVisibleCheckedTimer'
      );

      const renderCallback = () => {
        if (gantt.getTaskByTime()) {
          gantt.refreshData();
        }
      };
      timerManager.registerAutoTimeout(
        renderCallback,
        1000,
        'renderRenderTimer'
      );
    });
  };
  gantt.handleMultiSelectionWithActivities = handleMultiSelectionWithActivities;

  /**
   * This function check to select elements
   * @param {*} wholeSelection Array with ID's or Direct dhtmlx task instance to being checked
   * @param {*} arrayWithIds Default true, if array comes with pure ID's, if there is an array with objects inside must be false
   * @returns Filtered array about parents already selected
   */
  const clearParentsToAvoidOverRecursive = (
    wholeSelection,
    arrayWithIds = true
  ) => {
    let toSelectRefs = [];
    const toUnselectRefs = [];

    /** This hash maps will be used only with  handleNewDragMultiselect below */
    const activityToChildHash = {};
    wholeSelection.forEach((id, index) => {
      let ganttRef;
      if (arrayWithIds) {
        ganttRef = gantt.getTask(id);
      } else {
        ganttRef = id;
      }
      if (
        ganttRef.isParentTask ||
        !ganttRef.isTask ||
        ganttRef.progress > 99.999
      ) {
        ganttRef.mustApplyVisibleChecked = true;
        if (!activityToChildHash[ganttRef.id]) {
          activityToChildHash[ganttRef.id] = [];
        }
        toUnselectRefs.push(ganttRef);
      } else {
        toSelectRefs.push(ganttRef);
        if (activityToChildHash[ganttRef.parent])
          activityToChildHash[ganttRef.parent].push(ganttRef.id);
        ganttRef.mustApplyVisibleChecked = false;
      }
    });

    let toRemoveFromSelectableDueToHisParent = [];
    const keysArray = Object.keys(activityToChildHash);
    keysArray.map((key) => {
      const parentRef = gantt.getTask(key);
      if (!keysArray.includes(JSON.stringify(parentRef.parent))) {
        toSelectRefs.push(parentRef);
      }
      const childArrayIds = activityToChildHash[key];
      toRemoveFromSelectableDueToHisParent = [
        ...toRemoveFromSelectableDueToHisParent,
        ...childArrayIds
      ];
    });

    toSelectRefs = toSelectRefs.filter((taskToSelect) => {
      if (!toRemoveFromSelectableDueToHisParent.includes(taskToSelect.id))
        return true;
    });
    return {
      toSelectRefs,
      toUnselectRefs
    };
  };
  gantt.clearParentsToAvoidOverRecursive = clearParentsToAvoidOverRecursive;
};

const updateWeeklyRangeMarker = (
  gantt,
  weeklyRangeObject,
  id = 'weeklyrange'
) => {
  const weeklyRangeMarker = gantt.getMarker(id);

  if (!weeklyRangeMarker) {
    gantt.addMarker({
      start_date: weeklyRangeObject.start_date,
      end_date: weeklyRangeObject.end_date,
      css: `${gantt.isWeeklyCommited ? 'weekly-range-commited' : 'weekly-range'}`,
      id: id,
      text: ''
    });
  } else {
    weeklyRangeMarker.start_date = weeklyRangeObject.start_date;
    weeklyRangeMarker.end_date = weeklyRangeObject.end_date;
    weeklyRangeMarker.css = `${
      gantt.isWeeklyCommited ? 'weekly-range-commited' : 'weekly-range'
    }`;
    gantt.updateMarker(id);
  }
};

export const handleWeeklyRangeOnDataChange = ({ gantt, weeklyRangeObject }) => {
  if (weeklyRangeObject) {
    updateWeeklyRangeMarker(gantt, weeklyRangeObject);
  }
};
