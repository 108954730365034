import { isGrouped } from '../../GanttVisualization.helper';
import { hasConstraintHtml } from '../../../../utils/lookahead-common';

const RenderRoadblocks = ({ task = {}, gantt = {}, onlyRead = true }) => {
  const SHOW_INDICATOR_CONSTRAINT = true;

  if (!gantt) return `<div><div></div></div>`;

  const renderRoadblock = (constraint = null) => `<div>
                <span class="task-actions"
                    style="display: inline;visibility: visible">
                    ${isGrouped() ? null : ` <span>${constraint} </span>`}
                </span>
            </div>`;

  const renderActivityTask = () => {
    const hasConstraintJsx = hasConstraintHtml(
      task,
      () => window.ganttVisualization.addConstraint(task?.id),
      true,
      gantt.t,
      SHOW_INDICATOR_CONSTRAINT,
      task?.id
    );

    const htmlPonderatorConstraint = `<span class="roadblock-span">
                ${task.constraints?.length ? hasConstraintJsx : '-'}
            </span>`;

    const taskName = renderRoadblock(htmlPonderatorConstraint);
    return `<div>
                <div>
                    <span style="position: relative;width: 100%;text-align: center;display: block">
                        ${taskName}
                    </span>
                </div>
            </div>`;
  };

  const RenderRoadblocksColumn = () => {
    let isOdd = false;
    if (gantt.oddColsConfig && task.type === 'activitytask') {
      isOdd = gantt.oddColsConfig.name;
    }
    if (!task?.type) return `<div><div></div></div>`;
    return `<div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="cursor: ${onlyRead ? 'not-allowed' : 'pointer'}; overflow: visible">
                ${task.type === 'activitytask' ? renderActivityTask() : ''}
            </div>`;
  };
  return RenderRoadblocksColumn();
};

export default RenderRoadblocks;
