import { TimerManagerSingleton } from '../../../../utils/timerManager';

const RenderWeeklyMaterial = ({
  column,
  gantt,
  t = () => {},
  onlyRead = true,
  task = {},
  volatileTaskData,
  haveWeeklyPlan = false,
  getReferenceFromLookahead = () => {}
}) => {
  const timerManager = TimerManagerSingleton.getInstance();

  const { taskRef, activityRef } = getReferenceFromLookahead(task) || {
    taskRef: false,
    activityRef: false
  };

  const isOnlyReadElement = onlyRead || task.readonly;
  /** Task and parentask ref from lookahead */
  if (!task?.isTask) return '';
  if (!taskRef?.id) return '';

  // task = gantt.getTask(task.id)
  const item = { ...task };
  const tagResource = item.tagResource;
  volatileTaskData[taskRef.id] = {
    ...volatileTaskData[taskRef.id],
    quantity_parcial: taskRef.quantity_parcial
  };
  if (
    (!task.isTask && !task.is_milestone) ||
    (taskRef && taskRef.children.length)
  ) {
    return '';
  }
  /**
   * Note: This code below blocks the tasks with children to progress recursive feature
   */
  if (haveWeeklyPlan) {
    task = gantt.getTask(task.id);
    const valueTask =
      gantt.isWeeklyCommited.length > 0 &&
      !gantt.isWeeklyCommited.closed &&
      task.lean_status === 'Committed'
        ? task.quantity_parcial
        : 0;

    return task.quantity_parcial
      ? `<span class="vertical-center" style="cursor: not-allowed;">
                        ${
                          t('lang') === 'en'
                            ? parseFloat(valueTask).toFixed(2)
                            : parseFloat(valueTask).toFixed(2).replace('.', ',')
                        } ${tagResource ? tagResource : ''}
                    </span>`
      : `<span class="vertical-center"> - </span>`;
  }

  const onChangeFunction = (e) => {
    const quantity_parcial = e;
    if (parseFloat(quantity_parcial) > parseFloat(taskRef.remaining_quantity)) {
      return false;
    }
    let newCompromise = taskRef.progress;
    if (taskRef.total_quantity) {
      newCompromise =
        taskRef.progress + (quantity_parcial * 100) / taskRef.total_quantity;
    }

    /** Get do element to save it state on re render */
    taskRef.commitment_percentaje = newCompromise;
    taskRef.quantity_parcial = quantity_parcial;
    task.commitment_percentaje = taskRef.commitment_percentaje;
    task.quantity_parcial = taskRef.quantity_parcial;

    const updateTaskCallback = () => {
      gantt.updateTask(task.id);
    };
    timerManager.registerAutoTimeout(
      updateTaskCallback,
      500,
      'updateTaskTimer'
    );

    const autoScheduleCallback = () => {
      gantt.autoSchedule();
    };
    timerManager.registerAutoTimeout(
      autoScheduleCallback,
      1500,
      'autoScheduleTimer'
    );
  };

  task.onChangeWeekMaterial = onChangeFunction;

  if (isOnlyReadElement) {
    return `<span
                class="vertical-center"
                style="background-color: 'transparent';
            ">
                ${
                  ['quantity_parcial'].includes(column?.name)
                    ? `<div>
                            <span>${taskRef[column?.name]}</span>
                            <span style="margin-left: 5;" >${
                              task.tagResource
                            }</span>
                        </div>`
                    : `${parseFloat(task[column?.name]).toFixed(2)}`
                }
            </span>`;
  }

  // lean_status: "Committed"
  let valueTask = task.quantity_parcial ? task.quantity_parcial : 0;

  if (taskRef?.children?.length > 0 && column?.name != 'ponderator') {
    return `<span className="vertical-center" style="cursor: 'not-allowed';">
                    <span class="vertical-center" style="cursor: 'not-allowed';">
                        ${
                          t('lang') === 'en'
                            ? parseFloat(valueTask).toFixed(2)
                            : parseFloat(valueTask).toFixed(2).replace('.', ',')
                        } ${tagResource ?? ''}
                </span>
            </span>`;
  } else {
    return `<span class="vertical-center" style="cursor: 'not-allowed';">
        ${
          t('lang') === 'en'
            ? parseFloat(valueTask).toFixed(2)
            : parseFloat(valueTask).toFixed(2).replace('.', ',')
        } ${tagResource ?? ''}
    </span>`;
  }
};

export default RenderWeeklyMaterial;
