/** Import elements from library Antd */
import {
  findDeepGetTask,
  updateAsyncTaskGanttV2,
  collapseExpandActivity,
  isGrouped,
  createTask
} from '../../GanttVisualization.helper';
import { findDeepGetActivityOfTask } from '../../../../views/lookahead/planification/index.helper';
import {
  calculatePonderators,
  getTask,
  splitTextHTML
} from '../../../../utils/lookahead-common';
import childArrow from '../../../../assets/img/newlookahead/name/child-arrow.png';
import { isFeatureOn } from '../../../../utils/featureUtils';
import { trackingEvent } from '../../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { sentryLogger } from '../../../../monitor/monitor';

export const testIds = {
  RENDER_NAME_CONTAINER: 'RENDER_NAME_CONTAINER'
};

const TestWrapped = ({ children }) => {
  return <span data-testid={testIds.RENDER_NAME_CONTAINER}>{children}</span>;
};

const RenderName = ({
  task = {},
  node = {},
  column = {
    width: 0
  },
  activities = [],
  gantt = {},
  t = (text) => 'Fake Translation',
  onlyRead = true,
  setEditedInput = (...vals) => console.log(vals),
  index = 0,
  tableConfig = {},
  projectState = {},
  updateAsyncActivity = (...vals) => console.log(vals),
  updateRender = () => console.log('update render fake function'),
  data = {
    data: []
  },
  showDrawer = false,
  updateMemoryRefMaterialValues = () => {}
}) => {
  const defineGlobalFunctions = () => {
    if (window.ganttVisualization) {
      if (
        !window.ganttVisualization.lookaheadTemplates_collapseExpandActivity
      ) {
        window.ganttVisualization.lookaheadTemplates_collapseExpandActivity = (
          id
        ) => {
          try {
            const thisTask = window.ganttVisualization.getTask(id);
            collapseExpandActivity(thisTask, window.ganttVisualization);
          } catch (error) {
            sentryLogger.error('Error in collapseExpandActivity:', error);
          }
        };
      }

      if (!window.ganttVisualization.lookaheadTemplates_showDrawerClick) {
        window.ganttVisualization.lookaheadTemplates_showDrawerClick = (id) => {
          const thisTask = gantt.getTask(id);
          let thisActivityReference = null;
          const findAct = findDeepGetActivityOfTask(
            window.activities,
            'id',
            id
          );
          if (findAct) thisActivityReference = findAct;
          showDrawer(thisTask, thisActivityReference);
        };
      }
    }
  };

  defineGlobalFunctions();

  const taskId = task?.id ? task.id : 0;
  const dataTestId = 'data-testid="' + testIds.RENDER_NAME_CONTAINER + '"';
  if (!activities || !task) return `<div ${dataTestId}></div>`;
  const isOnlyReadElement = onlyRead || task.readonly; // || gantt?.config?.readonly
  /** Check if data is defined. Activity don't have this data */
  const item = task;
  /** get reference task (taskFromLookahead) from activities */
  let taskFromLookahead = null;
  if (item?.isTask) {
    /** get reference task (taskFromLookahead) from activities */
    taskFromLookahead = findDeepGetTask(activities, 'id', task.id);
  }

  /**
   *
   * @param {*} event
   * @param {*} task_id
   */
  const onClickMaster = (event, task_id) => {
    if (gantt && task && event) {
      const id = task_id;
      const e = event;
      // Connect react with dhtmlx api through dispatching this event
      gantt.callEvent('onTaskClick', [id, e]);
    }
  };

  const featureOpenCard = true;
  let activityReference = null;
  const findAct = findDeepGetActivityOfTask(window.activities, 'id', task?.id);
  if (findAct) activityReference = findAct;

  /** disabled value for parent tasks, get parent task  */
  let parentTask = null;
  // let disabled = true
  if (taskFromLookahead) {
    // disabled = (taskFromLookahead.children.length > 0)
    if (taskFromLookahead.parent_id) {
      parentTask = findDeepGetTask(
        activities,
        'id',
        taskFromLookahead.parent_id
      );
    }
  }

  if (!gantt) return `<div ${dataTestId}></div>`;

  /** calculate margin left according level */
  const itemLvl = item?.$level; // ? item.$level : 30
  let marginLeft = 26;
  if (taskFromLookahead?.children?.length) {
    marginLeft = 9;
  }

  /** margin calculation according to level */
  const fixedPos = (16 * itemLvl || 30) + marginLeft;
  const fixedPosAct = fixedPos;
  const fixedPosTask = isGrouped() ? 60 : fixedPos + 40;

  // if (!fixedPosTask) fixedPosTask = 30

  /** this function runs after updating the value */
  const updateAsyncTask = async (taskLocal) => {
    gantt.isRenderingOnProcess = false;
    await updateAsyncTaskGanttV2(
      taskLocal,
      column.name,
      taskLocal.name,
      gantt,
      true,
      false,
      false,
      true
    );
  };

  const addCopyOfActivity = async (activity) => {
    return await createTask(
      null,
      'New task',
      activity,
      true,
      projectState,
      updateAsyncActivity,
      updateRender,
      gantt
    );
  };

  const isSel = gantt.isSelectedTask(task.id);

  task.onTextChange = async (newValue) => {
    const taskCallback = taskFromLookahead;
    taskCallback.name = newValue;
    updateAsyncTaskGanttV2(
      taskCallback,
      column.name,
      taskCallback.name,
      gantt,
      true,
      false,
      false,
      true
    );

    trackingEvent(
      'task_name_edition',
      {
        ...getBasicAmplitudEventProperties(),
        task_id: taskCallback?.id,
        task_name: taskCallback?.name,
        parent_activity_name: taskCallback?.activity,
        parent_activity_id: taskCallback?.activityId
      },
      AMPLITUDE_SERVICE
    );
  };

  const renderTaskNameInput = (tasklocal, html_collapse_expand = null) => {
    return `<div class="gantt_tree_content" style="cursor: pointer">
                ${
                  task.parent &&
                  task.type === 'activitytask' &&
                  task.isChildTask
                    ? `<img style= "width:12px; margin-right: 6px, margin-left: ${!html_collapse_expand ? '10px' : '1px'}" src="${childArrow}" />`
                    : ''
                }
                ${task.name}
            </div>`;
  };

  const splitActivityRoute = (activityRoute) => {
    if (projectState?.allProjects?.length > 0) {
      if ([54, 479].includes(projectState?.allProjects[0]?.companyId)) {
        if (activityRoute) {
          const pieces = activityRoute.split(' > ');
          if (pieces) {
            if (pieces.length > 3) {
              return (
                pieces[pieces.length - 3] +
                ' > ' +
                pieces[pieces.length - 2] +
                ' > ' +
                pieces[pieces.length - 1]
              );
            } else {
              return activityRoute;
            }
          } else {
            return activityRoute;
          }
        } else {
          return activityRoute;
        }
      } else {
        return activityRoute;
      }
    } else {
      return activityRoute;
    }
  };

  const renderMain = () => {
    /** is Activity */
    const hideAddBtn =
      gantt.subtradeRole &&
      gantt.subtradeRole.isSubtradeRole &&
      !gantt.subtradeRole.subtrade;
    const haveChildsAct = data?.data?.some(
      (it) => !it.isTask && it.parent == item?.id
    );
    let activityReferenceLvl = null;
    if (!haveChildsAct) {
      activityReferenceLvl = activities.find((ac) => ac.unique_id == item?.id);
    }

    if (activityReferenceLvl) {
      const activity = activityReferenceLvl;
      if (activity.reCalculatePonderator) {
        activity.reCalculatePonderator = false;
        updateAsyncActivity(activity);
        const alreadyCalculatedParents = {};
        let activityAlreadyCalculated = false;
        activity.tasks.map((task) => {
          if (task.parent_id) {
            if (!alreadyCalculatedParents[task.parent]) {
              const parent = getTask(task.parent_id, null, activity);
              calculatePonderators(parent, null, updateAsyncTask, projectState);
              alreadyCalculatedParents[task.parent_id] = true;
            }
          } else if (!activityAlreadyCalculated) {
            calculatePonderators(activity, null, updateAsyncTask, projectState);
            activityAlreadyCalculated = true;
          }
        });
        gantt.render();
      }
    }

    let prefix = `${item?.ganttId || item?.correlative_id}| `;
    if (isGrouped()) {
      prefix = '';
    }
    const activityIdStr = `${prefix}${item?.name}`;
    const showDuplicate = !gantt.hasChild(item?.id) && !isGrouped();

    return `<div ${dataTestId}>
                <div
                    onClick="window.ganttVisualization.selectTask(${task.id})"
                    class="${(isFeatureOn('enable_lwp_18') ? 'custom-div-row-container' : '') + ' text-btn-gantt-view'}"
                    style="
                        color: #121212;
                        font-weight: 600;
                        position: relative;
                        left: ${fixedPosAct}px;
                        display: flex;
                        align-items: center;
                    "
                >
                    <span class="collapse-expand-gantt" onclick="window.ganttVisualization.lookaheadTemplates_collapseExpandActivity(${task.id})">
                        ${item?.$open ? '<div class="gantt_tree_icon gantt_close"></div>' : '<div class="gantt_tree_icon gantt_open"></div>'}
                    </span>

                    <span>
                        <span class="act-correlative-id">
                            <span class="main-activity-name">
                                ${splitTextHTML(activityIdStr, { ...column, width: column?.width }, item?.id)}
                            </span>
                            ${
                              isFeatureOn('enable_lwp_18')
                                ? `
                                <span class='main-activity-route'>
                                    ${splitTextHTML(splitActivityRoute(item?.activityRoute), column, item?.id)}
                                </span>
                            `
                                : ''
                            }
                        </span>
                    </span>

                </div>
            </div>`;
  };

  const renderActivityTask = () => {
    /** is Task */
    let html_collapse_expand =
      item?.is_parent || item?.isParentTask
        ? `<span class="collapse-expand-gantt expand-task" onclick="window.ganttVisualization.lookaheadTemplates_collapseExpandActivity(${task.id})">
                    ${item?.$open ? '<div class="gantt_tree_icon gantt_close"></div>' : '<div class="gantt_tree_icon gantt_open"></div>'}
                </span>`
        : '';

    const taskName = renderTaskNameInput(
      taskFromLookahead,
      html_collapse_expand
    );
    if (isGrouped()) {
      html_collapse_expand = null;
    }

    // FIT LEVELS VISUAL
    let marginAdjust;
    if (task.is_parent && itemLvl >= 1) {
      marginAdjust = '-15px !important;';
    }

    return `<span ${dataTestId}>
                <div style="display:flex; align-items: center; margin-left: ${task.is_parent && marginAdjust ? marginAdjust : '0px'}">
                    <span onClick="window.ganttVisualization.lookaheadTemplates_showDrawerClick(${task.id})" class="open-card-button-new custom-adjust-visual-tree"></span>
                    <span class="text-btn-gantt-view-new" style="display:flex; position: relative; left: ${fixedPosTask * 0.5}px;align-items: center">
                        ${html_collapse_expand}
                        ${taskName}
                    </span>
                </div>
            </span>`;
  };

  const renderMilestone = () => {
    return `<div>
                <div class="milestone-name-custom">
                    ${item?.name}
                </div>
            </div>`;
  };

  const renderNameColumn = () => {
    let isOdd = false;
    if (gantt.oddColsConfig && task.type === 'activitytask') {
      isOdd = gantt.oddColsConfig.name;
    }
    if (!item?.type) return renderMain();

    if (!window.ganttVisualization?.lookaheadTemplates_selectTask) {
      window.ganttVisualization.lookaheadTemplates_selectTask = (e, id) => {
        if (!e) e = window.event;
        onClickMaster(e, id);
        if (!gantt.isSelectedTask(id)) {
          gantt.unselectTask();
          gantt.selectTask(id);
        }
      };
    }

    return `<div ${dataTestId}>
                <div
                    class="${!isOdd ? 'odd-col' : 'non-odd-col'}"
                    onclick="window.ganttVisualization.lookaheadTemplates_selectTask(event,${item.id})"
                >
                    ${item.type === 'activitytask' ? renderActivityTask() : ''}
                    ${item.type === 'main' ? renderMain() : ''}
                    ${item.type === 'milestone' ? renderMilestone() : ''}
                </div>
            </div>`;
  };

  return renderNameColumn();
};

export default RenderName;
