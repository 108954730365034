import { ganttAPI } from '../../../../utils/customGanttPlugin';
import {
  calculatePonderators,
  getEndDateByGantt
} from '../../../../utils/lookahead-common';
import {
  transformHourToDays,
  transformDaysToHours
} from '../../../../views/ganttContainer/gantt/gantt.helper';
import {
  updateTaskMassive,
  addToQueueToSave,
  getReferences,
  updateParentDuration
} from '../../GanttVisualization.helper';

const updateTaskDrag = async (
  draggedTask,
  mode,
  projectState,
  gantt,
  arrToUpdate
) => {
  const { activityReference, taskFromLookahead, parentTask } =
    getReferences(draggedTask);

  if (!activityReference) return;

  taskFromLookahead.start_date = draggedTask.start_date;
  taskFromLookahead.end_date = draggedTask.end_date;

  if (mode === 'resize') {
    taskFromLookahead.duration = transformHourToDays(draggedTask.duration);
  }

  const durationInDays = taskFromLookahead.duration;

  /** calculate End date with new duration in Days */
  getEndDateByGantt(taskFromLookahead, activityReference);

  /** calculate new Duration with calendar */
  const durationInHours = transformDaysToHours(taskFromLookahead.duration);

  /** update references with new duration and end date */
  updateDurationsInTask(taskFromLookahead, durationInHours, durationInDays);

  /** Calculates ponderators feature */
  calculatePonderators(
    parentTask || activityReference,
    activityReference,
    (taskCallback) => {
      addToQueueToSave(arrToUpdate, taskCallback);
    },
    projectState
  );

  try {
    /** refresh view */
    addToQueueToSave(arrToUpdate, taskFromLookahead, [
      'start_date',
      'end_date',
      'duration'
    ]);

    /** update parent */
    if (taskFromLookahead.parent_id) {
      updateParentDuration(
        taskFromLookahead.parent_id,
        activityReference,
        arrToUpdate,
        gantt,
        ganttAPI,
        projectState
      );
    }
  } catch (e) {
    console.log(e);
  }
};

const updateDurationsInTask = (
  taskFromLookahead,
  durationInHours,
  durationInDays
) => {
  taskFromLookahead.duration = durationInDays;
  taskFromLookahead.for_disable_milestone_duration = durationInHours;
  taskFromLookahead.duration_milestone_bugged = durationInHours;
  taskFromLookahead.for_disable_milestone_duration = durationInHours;
  taskFromLookahead.non_parsed_original_duration = durationInHours;
  taskFromLookahead.durationDays = durationInDays;
};

export default updateTaskDrag;
