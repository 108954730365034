export const OnBeforeTaskDrag = ({ Helpers }) => {
  const helpers = Helpers;
  const groupBy = window.groupBy;

  const determineMultipleTaskDrag = (gantt, checkedTasks, id) => {
    gantt.dragWithMultiple = false;
    gantt.avoidAutoScheduleBeforeDrag = false;

    if (checkedTasks.length > 1) {
      gantt.dragWithMultiple = true;
      gantt.avoidAutoScheduleBeforeDrag = true;
    }

    return gantt.dragWithMultiple ? checkedTasks : [id];
  };

  const validateDraggedTask = (task) => {
    if (!task) return false;
    if (task.proplannerId || task.is_parent) return false;
    return true;
  };

  const dragModeHandlers = {
    move: (draggedTask, gantt) => {
      draggedTask.modeDragAux = 'move';
      if (draggedTask.progress > 99.999) {
        setTimeout(() => (gantt.maxPerformance = false), 1000);
        return false;
      }

      draggedTask.correct_baseline_worktime_bug = true;
      gantt.is_task_moved = true;

      if (draggedTask.task_added) {
        draggedTask.duration = draggedTask.aux_duration;
      }

      return true;
    },

    resize: (draggedTask, gantt) => {
      if (draggedTask.progress === 100) {
        setTimeout(() => (gantt.maxPerformance = false), 1000);
        return false;
      }

      draggedTask.modeDragAux = 'resize';
      gantt.copyBehaviourFromInline = true;
      draggedTask.last_start_date = draggedTask.start_date;
      draggedTask.is_open_lightbox = true;
      draggedTask.bug_duration_from_resize_drag = true;

      return true;
    }
  };

  const handleDragMode = (mode, dragModes, draggedTask, gantt) => {
    draggedTask.auto_scheduling = false;
    const dragMode = Object.entries(dragModes).find(
      ([_, value]) => value === mode
    )?.[0];
    const handler = dragModeHandlers[dragMode];

    return handler ? handler(draggedTask, gantt) : true;
  };

  const createBackupDatesForTasks = ({
    tasksToDrag,
    gantt,
    earliestTask,
    currentSector
  }) => {
    tasksToDrag.forEach((taskId) => {
      const task = gantt.getTask(taskId);
      if (!task) return;

      task.backupDates = helpers.createBackupDates(
        task,
        earliestTask?.start_date,
        currentSector
      );
      task.calendarWorkingHours = helpers.getStartAndEndHours(task);
    });
  };

  const execute = (dto) => {
    const { id, mode, gantt, currentSector } = dto;

    const checkedTasks = helpers.getAllCheckedTasksByTaskOnly(gantt);
    const tasksToDrag = determineMultipleTaskDrag(gantt, checkedTasks, id);

    const earliestTask = helpers.findEarliestTask(gantt, tasksToDrag);
    gantt.firstTaskInMoveBack = earliestTask;

    if (
      groupBy?.criteria &&
      !['activity', 'activityId'].includes(groupBy.criteria)
    ) {
      return null;
    }

    const draggedTask = gantt.getTask(id);
    if (!validateDraggedTask(draggedTask)) return false;

    gantt.maxPerformance = true;
    const dragModes = gantt.config.drag_mode;

    const isDragModeValid = handleDragMode(mode, dragModes, draggedTask, gantt);
    if (!isDragModeValid) return null;

    if (currentSector) {
      createBackupDatesForTasks({
        tasksToDrag,
        gantt,
        earliestTask,
        currentSector
      });
    }

    gantt.isEditingDrag = true;
    return true;
  };

  return {
    execute
  };
};
