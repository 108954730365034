import { OnBeforeTaskDrag } from './onBeforeTaskDrag';

import {
  getAllCheckedTasksByTaskOnly,
  findEarliestTask,
  createBackupDates
} from './helpers';
import { getStartAndEndHours } from '../utils';

const Helpers = {
  getAllCheckedTasksByTaskOnly,
  findEarliestTask,
  createBackupDates,
  getStartAndEndHours
};

const onBeforeTaskDrag = OnBeforeTaskDrag({
  Helpers
});

export default onBeforeTaskDrag;
