import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as ganttActions from '../../redux/slices/ganttSlice';

/** Function to configure the GANTT DHTMLX LIB */
import { gantt_general_config } from '../../assets/js/gantt-general-config';

import '../../assets/gantt/dhtmlxgantt.css';
import { configExportPlugin } from '../../assets/gantt/ext/dhtmlxgantt_export'; /** Export (PROJECT-PDF) API from dhtmlx */
import { buildCustomDropdown } from '../../assets/gantt/ext/custom_dropdown';
import useMasterplanPermissions from '../../hooks/useMasterplanPermissions';

import * as Sentry from '@sentry/react';
import { killStorage } from '../../assets/js/gantt_storage/index';
export const TestIds = {
  GANTT_CONTAINER: 'GANTT_CONTAINER'
};

export const Gantt = ({
  gantt = {},
  data = [],
  deleteHandler = () => {},
  detectChildAndLinks = () => {},
  t = () => {},
  setAutoscheduling = () => {},
  setAutoschedulingVisual = () => {},
  subContracts = [],
  toSelectResponsables = [],
  toSelectTags = [],
  setVisibleFormSubcontract = () => {},
  setVisibleFormTags = () => {},
  setZoomLevel = () => {},
  calendarObject = {},
  setActivityModificationSelected = () => {},
  setGanttLoaded = () => {},
  toDisable = [],
  modalCircular = () => {}
}) => {
  const [ganttContainer, setGanttContainer] = useState(null);
  const ganttState = useSelector((state) => state.ganttState);
  const projectState = useSelector((state) => state.projectState);
  const companyState = useSelector((state) => state.companyState);
  const companySettingsState = useSelector(
    (state) => state.companySettingsState
  );
  const copyPasteState = useSelector((state) => state.copyPasteState);
  const dispatch = useDispatch();
  const masterplanPer = useMasterplanPermissions();
  const submittalState = useSelector((state) => state.submittalState);

  useEffect(
    () => () => {
      delete window.to_use_react_gantt;
      gantt.disableColorsWBS && gantt.disableColorsWBS();
      killStorage();
    },
    []
  );

  /** Similar to componentDidMount */
  useEffect(() => {
    const sector = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    );
    /**
     * If a effect hoks means on
     * subscription, must return a
     * clean function to unsubscribe it
     * check docs at https://es.reactjs.org/docs/hooks-effect.html
     */
    if (ganttContainer) {
      /** NOTE: Working but not loading config with this method (shoudl study case) */
      let ganttEvents;
      try {
        ganttEvents = gantt_general_config(
          gantt,
          ganttContainer,
          data,
          deleteHandler,
          detectChildAndLinks,
          projectState,
          dispatch,
          t,
          sector,
          ganttState?.userPreferenceTableGantt, // VALIDACION
          ganttState?.visualizationConfig,
          ganttState?.lastUniqueCorrelativeIds,
          ganttState?.selectedActivities,
          setAutoscheduling,
          setAutoschedulingVisual,
          subContracts,
          toSelectResponsables,
          toSelectTags,
          setVisibleFormSubcontract,
          setVisibleFormTags,
          setZoomLevel,
          calendarObject,
          masterplanPer,
          companyState,
          companySettingsState,
          modalCircular,
          copyPasteState,
          submittalState
        );
        configExportPlugin(gantt, t);
        buildCustomDropdown(gantt, setActivityModificationSelected);
        gantt.Sentry = Sentry;

        ganttState &&
          ganttState.activatedColumns &&
          ganttState.activatedColumns.map((columnName) => {
            gantt.getGridColumn(columnName).hide = false;
          });

        toDisable &&
          toDisable.map((columnName) => {
            gantt.getGridColumn(columnName).hide = true;
          });

        if (
          ganttState?.userPreferenceTableGantt?.gridTable &&
          ganttState?.userPreferenceTableGantt?.gridTable?.length
        ) {
          ganttState.userPreferenceTableGantt.gridTable.forEach((column) => {
            const width =
              !column.width || column.width === '*' ? '100' : column.width;

            gantt.getGridColumn(column.name).width = width;
          });
        }

        gantt.render();
      } catch (e) {
        console.log('Error on Gantt component, please check the code');
        console.log(e);
      }

      setGanttLoaded(true);
      return () => {
        // detach all saved events
        try {
          dispatch(
            ganttActions.setFilteredByActivityWithDependencies([
              { filtered: [] }
            ])
          );
          buildCustomDropdown(gantt, setActivityModificationSelected, true);
          while (ganttEvents?.length) {
            gantt.detachEvent(ganttEvents.pop());
          }
        } catch (e) {
          console.log('check new destructor...');
          console.log(e);
        }
      };
    }

    /**
     * Giving an object param array,
     * you must specify that only on
     * it change this effect will be executed
     */
  }, [ganttContainer]);

  return (
    <div
      data-testid={TestIds.GANTT_CONTAINER}
      className="new-gantt-chart"
      ref={(input) => {
        setGanttContainer(input);
      }}
      style={{ width: '100%', height: '100%' }}></div>
  );
};

export default Gantt;
