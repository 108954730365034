import updateTaskDrag from './updateTaskDrag';
import { updateTaskMassive } from '../../GanttVisualization.helper';

const onAfterTaskDrag = (tasksToProcess, gantt) => {
  const arrToUpdate = [];

  tasksToProcess.forEach((currTask) => {
    const { id, mode, projectState } = currTask;
    const task = gantt.getTask(id);
    gantt.setInitialHourDay(task);

    updateForDisableMilestoneDurationProperty(gantt);

    gantt.is_task_moved = false;
    task.constraint_type = 'asap';
    gantt.updateCustomCommitmentPercentaje(task);
    setDuration(task);

    if (gantt.toOrderByQuery) {
      gantt.sort(eval(gantt.toOrderByQuery));
    }

    gantt.isEditingDrag = false;

    try {
      updateTaskDrag(task, mode, projectState, gantt, arrToUpdate);
    } catch (e) {
      console.log(e);
    }

    try {
      gantt.checkIfTaskIsInsideOfWeekly(task);
    } catch (e) {
      console.log(e);
    }

    gantt.updateTask(id);
  });
  updateTaskMassive(arrToUpdate);
};

function updateForDisableMilestoneDurationProperty(gantt) {
  const ganttIsAllowToReload =
    !gantt.isRenderingOnProcess || !gantt.completingOnProcess;
  if (ganttIsAllowToReload) {
    gantt.batchUpdate(() => {
      gantt.eachTask((task) => {
        task.for_disable_milestone_duration = task.duration;
      });
    }, true);
  }
}

function setDuration(task) {
  if (task.modeDragAux) {
    const mode = task.modeDragAux;

    if (mode === 'move') {
      if (task.duration_milestone_bugged) {
        task.duration = task.duration_milestone_bugged;
      }
    }

    if (mode === 'resize') {
      task.duration = task.for_disable_milestone_duration;
      task.duration_milestone_bugged = task.duration;
    }
  }
}
export default onAfterTaskDrag;
