export const getAllCheckedTasksByTaskOnly = (gantt) => {
  const currentGantt = gantt || window.to_use_react_gantt;
  return currentGantt
    .getTaskBy(
      (t) =>
        (t.visibleChecked || t.checked || t.mustApplyVisibleChecked) &&
        t.type === 'activitytask' &&
        !t.isParentTask
    )
    .map((t) => t.id);
};

export const findEarliestTask = (gantt, tasksIds) => {
  let earliestTask = null;
  tasksIds.forEach((taskId, index) => {
    const current = gantt.getTask(taskId);
    if (!current) return;
    if (index === 0) {
      earliestTask = current;
    } else {
      if (current.start_date < earliestTask.start_date) {
        earliestTask = current;
      }
    }
  });
  return earliestTask;
};

export const createBackupDates = (task, earliestTaskStart, currentSector) => {
  const {
    start_date: startDate,
    duration,
    end_date: endDate,
    calendar_id: calendarId
  } = task;
  const calendar = gantt.getCalendar(calendarId);

  let diffDays = 0;
  if (earliestTaskStart && calendar) {
    const rawDuration = calendar.calculateDuration(
      earliestTaskStart,
      startDate,
      calendarId
    );
    diffDays = rawDuration / currentSector.hoursPerDay;
  }

  const backupDates = {
    start_date: new Date(startDate),
    end_date: new Date(endDate),
    duration: duration,
    difference: diffDays
  };

  return backupDates;
};

export const unselectInvalidTasks = (gantt) => {
  const selectedTasks = gantt.getSelectedTasks();
  const containsInvalidTasks = selectedTasks.filter((taskId) => {
    const taskInSelection = gantt.getTask(taskId);
    return taskInSelection.type === 'main';
  });
  gantt.unselectTask(containsInvalidTasks);
};
