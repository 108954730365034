import React from 'react';
import { getEndDateByGantt } from '../../../../utils/lookahead-common';
import { findDeepGetActivityOfTask } from '../../../../views/lookahead/planification/index.helper';
import {
  findDeepGetTask,
  isGrouped,
  updateAsyncTaskGanttV2,
  recursiveGetStartForParent,
  getStringFromMoment,
  getRecursiveDurationForParent
} from '../../GanttVisualization.helper';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { trackingEvent } from '../../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { TimerManagerSingleton } from '../../../../utils/timerManager';

const TestIds = {
  RENDER_START_TRIGGER_: 'RENDER_START_TRIGGER_'
};

const RenderStart = ({
  task = { id: 0 },
  node,
  column = { name: '' },
  activities,
  gantt,
  t,
  onlyRead,
  ganttAPI,
  projectState,
  updateTaskDrag
}) => {
  const timerManager = TimerManagerSingleton.getInstance();
  const taskId = task ? task.id : 0;
  const columnName = column ? column.name.toUpperCase() : '';
  const dataTestId =
    'data-testid="' +
    TestIds.RENDER_START_TRIGGER_ +
    taskId +
    '_' +
    columnName +
    '"';
  if (task && task.is_milestone) return null;
  /** No Render if parent is Abstract */
  if (isGrouped()) {
    if (task.type === undefined) {
      return <span></span>;
    }
  }
  if (!task?.isTask) {
    return `
            <span ${dataTestId} className="vertical-center-dates" style="cursor: ${onlyRead ? 'not-allowed' : 'pointer'}">
                <span className="column-disabled">${moment(task?.start_date).format(gantt?.currentDateFormat?.replace('hh', 'HH'))}</span>
            </span>
            `;
  }

  /** get reference task (taskFromLookahead) from activities */

  const findAct = findDeepGetActivityOfTask(window.activities, 'id', taskId);
  let activityReference;
  if (findAct) activityReference = findAct;
  if (!activityReference) return null;

  /** get reference task (taskFromLookahead) from activities */
  const taskFromLookahead = findDeepGetTask(activities, 'id', taskId);
  if (!taskFromLookahead) return null;

  /** disabled value for parent tasks */
  let disabled = true;
  if (taskFromLookahead) {
    disabled =
      taskFromLookahead.children.length > 0 ||
      taskFromLookahead.progress > 99.9;
  }

  /** calculate at render */
  if (taskFromLookahead.children.length) {
    recursiveGetStartForParent(taskFromLookahead, column, gantt);
  }

  let classDate = 'custom-date-picker-planification-tv';
  if (task.active) {
    classDate += ' massive';
  }
  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig[column.name];
  }

  const onChangeStart = (date) => {
    gantt.config.readonly = true;

    const configCallback = () => {
      gantt.config.readonly = false;
    };
    timerManager.registerAutoTimeout(
      configCallback,
      1300,
      'renderStartConfigTimer'
    );
    gantt.updateCustomCommitmentPercentaje(task, false);

    const newDate = cloneDeep(date);
    taskFromLookahead[column.name] = newDate;
    getEndDateByGantt(taskFromLookahead, activityReference);

    const fieldsToUpdate = ['start_date', 'duration', 'end_date'];
    updateAsyncTaskGanttV2(taskFromLookahead, fieldsToUpdate, null, gantt);

    trackingEvent(
      'task_start_date_edition',
      {
        ...getBasicAmplitudEventProperties(),
        task_id: taskFromLookahead?.id,
        task_name: taskFromLookahead?.name,
        parent_activity_name: activityReference?.name,
        parent_activity_id: activityReference?.id
      },
      AMPLITUDE_SERVICE
    );

    /** update other fields */
    const taskFromGantt = gantt.getTask(taskFromLookahead.id);
    updateTaskDrag(taskFromGantt.id, taskFromGantt);
  };
  task.onChangeStart = onChangeStart;

  const momentDate = moment(task.start_date);

  return `
            <div ${dataTestId} class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="cursor: ${onlyRead ? 'not-allowed' : 'pointer'}">
                ${momentDate.format(gantt?.currentDateFormat?.replace('hh', 'HH'))}
            </div>
            `;
};

export default RenderStart;
